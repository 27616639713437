import { Box, Heading } from "@chakra-ui/layout"
import React from "react"
import { Layout } from "../components/layoutEmpty"
export default function NotFoundPage() {
  return (
    <Box
      textAlign={'center'}
      position={'fixed'}
      top={'50%'}
      left={'50%'}
      transform={'translate(-50%, -50%)'}
    >
      <Box>
        <Heading as={'h3'}>PAGE NOT FOUND</Heading>
      </Box>
      <Box mt={10}>
        <Heading as={'h2'} >
          <p>
            Please visit{' '}
            <a className="has-text-black" aria-label="INGMARSON" href="/">
              INGMARSON
            </a>
          </p>
        </Heading>
      </Box>
    </Box>
  )
}

NotFoundPage.Layout = Layout

